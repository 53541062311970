<template>
	<div class="border h-100">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Overall Stats</div>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Issued </span>
				</p>
				<div class="">
					<p class="fw-600 green--text mb-0" style="font-size: 30px">{{ checkout }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="checkout" color="green">
					{{ checkout }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Received </span>
				</p>
				<div class="">
					<p class="fw-600 red--text mb-0" style="font-size: 30px">{{ checkin }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="checkin" color="red">
					{{ checkin }}%
				</v-progress-circular>
			</div>
		</div>
		<div
			v-if="false"
			class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center"
		>
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Maintenance </span>
				</p>
				<div class="">
					<p class="fw-600 indigo--text mb-0" style="font-size: 30px">{{ service }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="service" color="indigo">
					{{ service }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 border-bottom d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Reserved </span>
				</p>
				<div class="">
					<p class="fw-600 orange--text mb-0" style="font-size: 30px">{{ reserved }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="reserved" color="orange">
					{{ reserved }}%
				</v-progress-circular>
			</div>
		</div>
		<div class="px-4 py-3 w-100 d-flex justify-content-between align-items-center">
			<div>
				<p class="my-auto font-level-3-bold mr-2">
					<span class="fw-600">Asset Retired </span>
				</p>
				<div class="">
					<p class="fw-600 cyan--text mb-0" style="font-size: 30px">{{ retired }}</p>
				</div>
			</div>
			<div class="text-center">
				<v-progress-circular rotate="360" size="70" width="08" :value="retired" color="cyan">
					{{ retired }}%
				</v-progress-circular>
			</div>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
//import ObjectPath from "object-path";

export default {
	name: "OverAllStats",
	data() {
		return {
			checkout: 0,
			checkin: 0,
			service: 0,
			reserved: 0,
			retired: 0,
		};
	},
	methods: {
		getAllstate() {
			ApiService.setHeader();
			ApiService.get("dashboard-overall-state").then((output) => {
				this.checkout = output.data.checkoutData;
				this.checkin = output.data.checkin;
				this.service = output.data.services;
				this.reserved = output.data.totalReservations;
				this.retired = output.data.totalretirements;
			});
		},
	},
	mounted() {
		this.getAllstate();
	},
};
</script>
